<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="10" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>每页条数</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
        </b-col>
      </b-row>
    </div>

    <b-table
      bordered
      tbody-tr-class="text-center"
      select-mode="multi"
      ref="orderListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableClumn"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative text-center"
    >
      <template #cell(member)="data">
        <div class="font-medium-2">
          {{ data.item.member.real_name }}
        </div>
        <div class="text-gray">
          {{ data.item.member.id }}
        </div>
        <div class="text-gray">
          {{ data.item.member.level.name }}
        </div>
      </template>

      <template #cell(order)="data">
        <div class="font-medium-2">
          {{ data.item.order.product.brand.title }}
        </div>
        <div>
          {{ data.item.order.product.title }}
        </div>
      </template>

      <div class="mx-2 mb-2">
        <b-row>
          <!--        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">-->
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          <!--        </b-col>-->
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="pages" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRadio, BFormRadioGroup, BFormTimepicker, BFormDatepicker } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { reactive, getCurrentInstance, nextTick, ref, watch } from '@vue/composition-api'
import { required } from '@core/utils/validations/validations'
import axiosIns from '@/libs/axios'
import useInvoicesList from './useOrderList'

import orderModule from './OrderManagerList'

export default {
  components: {
    BFormDatepicker,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormRadio,
    BFormRadioGroup,
  },
  filters: {
    filterStatusTxt(value) {
      switch (value) {
        case 1:
          return '已提交'
        case 2:
          return '已处理'
        case 3:
          return '已生效'
        default:
          return '未知'
      }
    },
    filterStatusColor(value) {
      switch (value) {
        case 1:
          return 'primary'
        case 2:
          return 'success'
        case 3:
          return 'success'
        default:
          return 'danger'
      }
    },
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const perPage = ref(100)
    const perPageOptions = [10, 20, 30, 40, 50]
    const totalInvoices = ref(0)

    const pages = ref(1)
    const fetchInvoices = (ctx, callback) => {
      axiosIns
        .get('/finance/getTeamData', {
          params: {
            page: pages.value,
            pageSize: perPage.value,
          },
        })
        .then(res => {
          callback(res.data.list)
          totalInvoices.value = res.data.total || 100
        })
    }

    watch([perPage], () => {
      fetchInvoices()
    })

    const dateQuery = ref('')

    const tableClumn = [
      {
        key: 'member',
        label: '开单人',
        thStyle: {
          width: '160px',
          whiteSpace: 'nowrap',
        },
      },
      {
        key: 'order',
        label: '开单产品',
        thStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        key: 'level_percent',
        label: '等级差百分比',
        thStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        label: '奖励百分比',
        key: 'gift_percent',
        thStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        label: '应收佣金',
        key: 'should_money',
        thStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        label: '实收佣金',
        key: 'real_money',
        thStyle: {
          whiteSpace: 'nowrap',
        },
      },
      {
        label: '状态',
        key: 'money_stu',
      },
      {
        label: '开单时间',
        key: 'open_order_time',
        thStyle: {
          whiteSpace: 'nowrap',
        },
      },
    ]

    return {
      pages,
      totalInvoices,
      perPageOptions,
      perPage,
      tableClumn,
      required,
      dateQuery,
      fetchInvoices,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.wocc {
  >>> .dropdown-toggle {
    padding: 0px !important;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
